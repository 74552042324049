import React, {useEffect, useState, useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {Routes, Route, useNavigate} from 'react-router-dom';
import MainLayout from 'pages/MainLayout';
import Login from 'pages/login';
import FirstAccess from 'pages/first-access';
import Top from 'pages/top';
import Dashboard from 'pages/dashboard';
import Setting from 'pages/setting';
import Survey from 'pages/survey';
import {AuthenticationGuard} from 'components/authentication-guard';
import NoMatch from 'pages/NoMatch';
import UserRegister from 'pages/user-register';
import NoCoursePage from 'pages/NoCoursePage';
import {useAuth0} from '@auth0/auth0-react';
import {AxiosRequestConfig} from 'axios';
import Career from 'pages/career';
import {callCoreApi} from 'services/core-api.service';
import FellowshipEvent from 'pages/FellowshipEvent';
import {PageLoader} from 'components/page-loader';
import {AuthUserContext, AuthUserContextType} from 'providers/AuthUserProvider';
import PrivacyPolicy from 'pages/privacy-policy';
import Community from 'pages/community/Community';
import QuestionSupport from 'pages/question-support';
import SpecialLesson from 'pages/special-lesson';
import Lesson from 'pages/lesson/Lesson';
import ProjectSupport from 'pages/project-support';

interface ICourse {
	course_description: string;
	course_id: string;
	course_name: string;
	course_price: number;
	course_type: string;
	created_at: string;
	updated_at: string;
}

function App() {
	const {user, getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const [userContractCourseName, setUserContractCourseName] = useState<string | undefined>();
	const navigate = useNavigate();
	const authUserContext = useContext<AuthUserContextType>(AuthUserContext);
	const zeroplus_id = user?.['https://zero-plus.io/user_metadata'].zeroplus_id;

	const search = useLocation().search;
	const query = new URLSearchParams(search);
	const theOnlineClass = query.get('the-online-class');
	const jobChange = query.get('job-change') as 'experienced' | 'inexperienced';
	const meetUp = query.get('meet-up');
	const getStudentData = async () => {
		const token = await getAccessTokenSilently();
		const config: AxiosRequestConfig = {
			method: 'get',
			url: `${process.env.REACT_APP_API_URL}/customer/student/zp_id/${zeroplus_id}/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		};
		const {responseData, error} = await callCoreApi(config);
		if (responseData && responseData[0]) {
			authUserContext?.setContractStudentData(responseData[0]);
			return responseData[0].course;
		}
		if (error) {
			return null;
		}
	};

	const getStudentClassroomData = async () => {
		const token = await getAccessTokenSilently();
		const config: AxiosRequestConfig = {
			method: 'get',
			url: `${process.env.REACT_APP_API_URL}/customer/classroom/zp_id/${zeroplus_id}/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		};
		const {responseData, error} = await callCoreApi(config);
		if (responseData) {
			authUserContext.setStudentClassroomData(responseData);
		}
	};

	const getCourseList = async () => {
		const token = await getAccessTokenSilently();
		const config: AxiosRequestConfig = {
			method: 'get',
			url: `${process.env.REACT_APP_API_URL}/customer/course/list/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		};
		const {responseData, error} = await callCoreApi(config);
		if (responseData) {
			return responseData;
		}
		if (error) {
			return null;
		}
	};

	const getUserDetail = async () => {
		const token = await getAccessTokenSilently();
		const config: AxiosRequestConfig = {
			method: 'get',
			url: `${process.env.REACT_APP_API_URL}/customer/user/detail/${zeroplus_id}/`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		};
		const {responseData, error} = await callCoreApi(config);
		if (responseData) {
			authUserContext.signin(responseData);
			return responseData;
		}
		if (error) {
			return null;
		}
	};

	const getContractCourseName = async () => {
		const studentCourseId = await getStudentData();
		const courseList = await getCourseList();
		if (studentCourseId && courseList) {
			const courseName = courseList.find((course: ICourse) => course.course_id === studentCourseId)?.course_name;
			setUserContractCourseName(courseName);
			authUserContext.setUserContractCourseName(courseName);
		} else {
			setUserContractCourseName('None');
		}
	};

	const navigateTestForm = () => {
		if (authUserContext?.user?.first_name) {
			const userName = authUserContext?.user?.last_name + authUserContext?.user?.first_name;
			const sanitizedZeroplusId = zeroplus_id.replace(/-/g, '');
			const webDesignUrl = `https://docs.google.com/forms/d/e/1FAIpQLSdnRPv0Ar_DqUgEjUNktg-D5DpV1p-U_Wmf_oeoYA78xP2AgQ/viewform?usp=pp_url&entry.690521538=${sanitizedZeroplusId}`;
			const webAppUrl = `https://docs.google.com/forms/d/e/1FAIpQLSdfha14n81RV0iTFKW-TxPkV5y43g9pXRmX7sck4a6OxG7f0A/viewform?usp=pp_url&entry.1548118430=${sanitizedZeroplusId}`;
			if (authUserContext.userContractCourseName === 'Webデザイン') {
				window.location.href = webDesignUrl;
			} else if (authUserContext.userContractCourseName === 'Webアプリケーション') {
				window.location.href = webAppUrl;
			}
		}
	};

	const navigateToJobChangeForm = (experienced: 'experienced' | 'inexperienced') => {
		if (zeroplus_id) {
			const sanitizedZeroplusId = zeroplus_id.replace(/-/g, '');
			const urls = {
				experienced: `https://docs.google.com/forms/d/e/1FAIpQLSdT5bkdaBQaXPnxCzDrbZyTNWt392v2YVuoU3P_8pfMtioEiA/viewform?usp=dialog&usp=pp_url&entry.582276847=${sanitizedZeroplusId}`,
				inexperienced: `https://docs.google.com/forms/d/e/1FAIpQLSfq-4AD8H3wiA9jXnYQSeEbte7Px2Ecpwf1xPz1Pxs_P8d9Lw/viewform?usp=dialog&usp=pp_url&entry.582276847=${sanitizedZeroplusId}`
			};
			if (urls[experienced]) {
				window.location.href = urls[experienced];
			}
		}
	};

	const navigateToMeetUpForm = () => {
		if (zeroplus_id) {
			const sanitizedZeroplusId = zeroplus_id.replace(/-/g, '');
			const url = `https://docs.google.com/forms/d/e/1FAIpQLSdLeFAGGj0mKVEz121MpnFbYpA6k8w0jG5WM75iTa0asDto9A/viewform?usp=dialog&usp=pp_url&entry.1235784981=${sanitizedZeroplusId}`;
			window.location.href = url;
		}
	};

	useEffect(() => {
		if (!isLoading) {
			if (isAuthenticated) {
				if (user && user?.['https://zero-plus.io/user_metadata'].zeroplus_id) {
					getContractCourseName();
					getUserDetail();
					getStudentClassroomData();
					if (authUserContext.isUserRegistered === 0 && userContractCourseName !== undefined) {
						// TODO：登録が完全に済んでない場合もnavigateするように設定
						navigate('/register');
					}
				}

				if (user && user?.['https://zero-plus.io/user_metadata'].zeroplus_id === undefined) {
					navigate('/first-access');
					setUserContractCourseName('None');
				}
			}
		}
	}, [user]);

	useEffect(() => {
		if (theOnlineClass === 'entry-exam') {
			navigateTestForm();
		}
		if (jobChange) {
			navigateToJobChangeForm(jobChange);
		}
		if (meetUp === 'true') {
			navigateToMeetUpForm();
		}
	}, [authUserContext?.userContractCourseName]);

	if (isLoading || (!isLoading && isAuthenticated && userContractCourseName === undefined)) {
		return <PageLoader />;
	} else {
		return (
			<Routes>
				<Route path='/Login' element={<Login />} />
				<Route path='/*' element={<AuthenticationGuard component={NoMatch} />}></Route>
				<Route path='/first-access' element={<AuthenticationGuard component={FirstAccess} />}></Route>
				<Route path='/register' element={<AuthenticationGuard component={UserRegister} />} />
				{(() => {
					if (userContractCourseName === 'Webデザイン' || userContractCourseName === 'Webアプリケーション') {
						return (
							<Route element={<AuthenticationGuard component={MainLayout} />}>
								<Route path='/' element={<Top />} />
								<Route path='/question-support' element={<QuestionSupport />} />
								<Route path='/survey' element={<Survey />} />
								<Route path='/lesson' element={<Lesson />} />
								<Route path='/special-lesson' element={<SpecialLesson />} />
								<Route path='/dashboard' element={<Dashboard />} />
								<Route path='/setting' element={<Setting />} />
								<Route path='/community' element={<Community />} />
								<Route path='/career' element={<Career />} />
								<Route path='/fellowship' element={<FellowshipEvent />} />
								<Route path='/privacy-policy' element={<PrivacyPolicy />} />
								<Route path='/project-support' element={<ProjectSupport />} />
							</Route>
						);
					} else if (userContractCourseName === 'None') {
						return <Route path='/' element={<AuthenticationGuard component={NoCoursePage} />} />;
					}
				})()}
			</Routes>
		);
	}
}

export default App;
